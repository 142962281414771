import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'
import axios from 'axios'

import './BriefForm.css'

const BriefForm = () => {
  const [isSent, setIsSent] = useState(false)

  // zmiana na use Memo!
  const [notification, setNotification] = useState({
    state: false,
    message: '',
  })

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid, isSubmitted },
    reset,
  } = useForm({
    mode: 'onChange',
  })

  const isDisabled = isSubmitted && !isSent ? ' disabled' : ''

  const textNotification = {
    required: 'To pole jest wymagane.',
    requiredEmail: 'E-mail jest wymagany, znacząco ułatwia kontakt.',
    minBudget: 'Nie, nie będziemy śpiewać "mniej niż 0".',
    maxBudget: 'WOW! Ale ta inflacja zapitala...',
    minLengthName: 'Imię musi mieć minimum 3 znaki.',
    maxLengthName: 'Bez przesady, masz do dyspozycji maksymalnie 40 znaków.',
    minLengthMessage: 'Wiadomość musi mieć minimum 15 znaków.',
    maxLengthMessage: 'Wiadomość może zawierać maksymalnie 1000 znaków.',
    sending: 'Wysyłanie wiadomości...',
    deliveredEmail: 'Twoja ostatnia wiadomość jest już u mnie 😄.',
    invalidName: 'Ups... użyto dość nietypowych znaków jak na imię.',
    invalidEmail: 'Hmm... to nie wygląda mi na prawdziwy adres e-mail.',
    invalidService: 'H4k3r alert... ktoś grzebał przy wartości danej usługi...',
    invalidBudget: 'Do dyspozycji jest jedynie zbiór liczb naturalnych.',
    invalidMessage: 'Ups... użyto niedozwolonych znaków w wiadomości.',
    error: 'Błąd wysyłania wiadomości.',
    errorNet: 'Błąd wysyłania wiadomości, sprawdź połączenie z internetem.',
    errorTimeout: 'Błąd wysyłania wiadomości, przekroczono limit czasu.',
    successTitle: '✉️ Poleciałooooo!',
    successMessage:
      'Jeżeli to nie weekend, święta albo inna okazja do odpoczynku, to postaram się odpisać w ciągu 24h 😄 Do usłyszenia wkrótce!',
  }

  const formSetup = {
    title: 'Zrealizujmy Twój pomysł 🚀',
    name: {
      label: 'Jak masz na imię?',
      placeholder: 'Jan Kowalski',
      regex:
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčśšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŚŽ∂ð,.' -]+$/u,
    },
    email: {
      label: 'Jaki jest Twój adres e-mail?',
      placeholder: 'jan@kowalski.pl',
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    service: {
      label: 'Jaką usługą jesteś zainteresowana/y?',
      placeholder: 'Wybierz usługę',
      regex:
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŚŽ∂ð -]+$/u,
    },
    budget: {
      label: 'Jaki budżet (w pln) chcesz przeznaczyć na realizację?',
      placeholder: '5000',
      regex: /^(?=.*\d)[\d]+$/g,
    },
    message: {
      label: 'Twoja wiadomość',
      placeholder: 'Siema Marcin! Możesz mi pomóc z...  ',
      regex:
        /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŚŽ∂ð ,.!?@_/:+=\-()"'\n]+$/iu,
    },
    button: {
      text: 'Wyślij',
    },
  }

  const services = [
    'Strona internetowa',
    'Sklep internetowy',
    'Wsparcie dla biznesu online',
    'Intergracje i automatyzacje',
    'Opieka i modernizowanie',
    'Projekty graficzne',
  ]

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const onSubmit = async values => {
    setNotification({ state: true, message: textNotification.sending })

    const data = JSON.stringify(values)

    const headers = {
      'Access-Control-Allow-Origin': process.env.CORS_DEFAULT_ORIGIN,
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/json; charset=UTF-8',
    }

    axios
      .post(process.env.CF_ADDRESS_ENDPOINT, data, {
        headers: headers,
        timeout: 5000,
      })
      .then(result => {
        setIsSent(true)
        setNotification({
          state: true,
          message: result?.data?.message,
        })
      })
      .catch(error => {
        setIsSent(false)
        reset(isSubmitted)

        if (error?.code) {
          error.code === 'ERR_NETWORK' &&
            (error.message = textNotification.errorNet)
          error.code === 'ECONNABORTED' &&
            (error.message = textNotification.errorTimeout)

          setNotification({
            state: true,
            message: error?.response?.data?.message || error?.message,
          })
        }
      })
  }

  const handleBackToForm = e => {
    e.preventDefault()
    reset()
    setIsSent(false)
    setNotification({
      state: true,
      message: textNotification.deliveredEmail,
    })
  }

  const showForm = (
    <>
      <h1 className="brief-title">{formSetup.title}</h1>
      <form
        className={`brief-form` + isDisabled}
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-col">
          <span className="form-no">01</span>
          <label className="label" htmlFor="name">
            {formSetup.name.label}
          </label>
          <input
            {...register('name', {
              required: {
                value: true,
                message: textNotification.required,
              },
              minLength: {
                value: 3,
                message: textNotification.minLengthName,
              },
              maxLength: {
                value: 40,
                message: textNotification.maxLengthName,
              },
              pattern: {
                value: formSetup.name.regex,
                message: textNotification.invalidName,
              },
              disabled: isSubmitted,
            })}
            className="input-field"
            placeholder={formSetup.name.placeholder}
          ></input>
          {errors?.name && (
            <span className="input-error">{errors?.name?.message}</span>
          )}
        </div>
        <div className="form-col">
          <span className="form-no">02</span>
          <label className="label" htmlFor="email">
            {formSetup.email.label}
          </label>
          <input
            {...register('email', {
              required: {
                value: true,
                message: textNotification.requiredEmail,
              },
              pattern: {
                value: formSetup.email.regex,
                message: textNotification.invalidEmail,
              },
              disabled: isSubmitted,
            })}
            className="input-field"
            placeholder={formSetup.email.placeholder}
          ></input>
          {errors?.email && (
            <span className="input-error">{errors?.email?.message}</span>
          )}
        </div>
        <div className="form-col">
          <span className="form-no">03</span>
          <label className="label" htmlFor="service">
            {formSetup.service.label}
          </label>
          <select
            {...register('service', {
              required: {
                value: true,
                message: 'Wybierz proszę usługę',
              },
              pattern: {
                value: formSetup.service.regex,
                message: textNotification.invalidService,
              },
              disabled: isSubmitted,
            })}
            className="input-field"
            placeholder={formSetup.service.placeholder}
          >
            {services.map(service => (
              <option key={service} value={service}>
                {service}
              </option>
            ))}
          </select>
          {errors?.service && (
            <span className="input-error">{errors?.service?.message}</span>
          )}
        </div>
        <div className="form-col">
          <span className="form-no">04</span>
          <label className="label" htmlFor="budget">
            {formSetup.budget.label}
          </label>
          <input
            {...register('budget', {
              min: {
                value: 0,
                message: textNotification.minBudget,
              },
              max: {
                value: 99999999,
                message: textNotification.maxBudget,
              },
              pattern: {
                value: formSetup.budget.regex,
                message: textNotification.invalidBudget,
              },
              disabled: isSubmitted,
            })}
            className="input-field"
            placeholder={formSetup.budget.placeholder}
          ></input>
          {errors?.budget && (
            <span className="input-error">{errors?.budget?.message}</span>
          )}
        </div>
        <div className="form-col">
          <span className="form-no">05</span>
          <label className="label" htmlFor="message">
            {formSetup.message.label}
          </label>
          <textarea
            {...register('message', {
              required: {
                value: true,
                message: textNotification.required,
              },
              minLength: {
                value: 15,
                message: textNotification.minLengthMessage,
              },
              maxLength: {
                value: 1000,
                message: textNotification.maxLengthMessage,
              },
              pattern: {
                value: formSetup.message.regex,
                message: textNotification.invalidMessage,
              },
              disabled: isSubmitted,
            })}
            className="input-field"
            rows="2"
            placeholder={formSetup.message.placeholder}
          ></textarea>
          {errors?.message && (
            <span className="input-error">{errors?.message?.message}</span>
          )}
        </div>
        <div className="form-col">
          {!isSubmitted && !isSent && (
            <button type="submit" className="btn" disabled={!isValid}>
              {formSetup.button.text}
            </button>
          )}
        </div>
      </form>
      {notification?.state && !isSent && (
        <div className="brief-notification center">
          <span>{notification?.message}</span>
        </div>
      )}
    </>
  )

  const showThankYou = (
    <>
      <h1 className="brief-title">{textNotification.successTitle}</h1>
      <div className="brief-form">
        <p>{textNotification.successMessage}</p>
      </div>
      <div className="brief-nav">
        <Link className="btn" to="/">
          Home
        </Link>
        <button className="btn" onClick={handleBackToForm}>
          Formularz
        </button>
      </div>
    </>
  )

  return (
    <section id="brief" className="bg-dark">
      {isSubmitted && isSent ? showThankYou : showForm}
    </section>
  )
}

export default BriefForm
