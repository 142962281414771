import * as React from 'react'
import Seo from '../components/Seo/Seo'
import Layout from '../components/Layout/Layout'
import BriefForm from '../components/BriefForm/BriefForm'

const BriefPage = () => {
  return (
    <>
      <Seo
        title="Brief Me!"
        description="Z chęcią przygotuję wycenę Twojego projektu za 'freebie zip zero nada'! Wystarczy, że wypełnisz krótki formularz, który nie zajmie Ci więcej niż 3 minuty."
      />
      <Layout>
        <BriefForm />
      </Layout>
    </>
  )
}

export default BriefPage
